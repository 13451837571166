import axios from 'axios';

import { downloadFile } from '../helpers/helpers';

const MAILCHIMP_SERVICE = {
    async setListMemberOnlyEmail(serializedForm, form) {
        let responseMessage = form.querySelector('.response-message');
        try {
            let response = await axios({
                method: 'post',
                url: `${process.env.MAILCHIMP_ENDPOINT}/set-list-member-only-email`,
                data: serializedForm
            });
            form.querySelector('button').classList.remove('is-loading');
            form.reset();
            responseMessage.classList.add('text-success');
            responseMessage.innerHTML = 'Thank you for your registration!';
            return response;
        } catch (error) {
            form.querySelector('button').classList.remove('is-loading');
            form.reset();
            responseMessage.classList.add('text-error');
            responseMessage.innerHTML = 'An error occurred while sending the data!';
            return error;
        } finally {
            setTimeout((e) => {
                responseMessage.innerHTML = '';
            }, 4000);
        }
    },
    async setListMember(serializedForm) {
        const formElement = document.getElementById('mailchimp-subscribe-form');
        let responseMessage = document.querySelector('#mailchimp-subscribe-form .response-message');
        try {
             let response = await axios({
                method: 'post',
                url: `${process.env.MAILCHIMP_ENDPOINT}/set-list-member`,
                data: serializedForm
            });
            document.querySelector('#mailchimp-subscribe-form button svg').classList.add('hidden');
            formElement.reset();
            responseMessage.classList.add('text-success');
            responseMessage.innerHTML = 'Thank you for your registration!';

            setTimeout((e) => {
                const whitepaperURL = `${process.env.BASE_URL}/media/pharmatrace-whitepaper.pdf`;
                const whitepaperName = 'pharmatrace-whitepaper';
                downloadFile(whitepaperURL, whitepaperName);
            }, 500);
            return response;
        } catch (error) {
            document.querySelector('#mailchimp-subscribe-form button svg').classList.add('hidden');
            formElement.reset();
            responseMessage.classList.add('text-error');
            responseMessage.innerHTML = 'An error occurred while sending the data!';
            return error;
        } finally {
            setTimeout((e) => {
                responseMessage.innerHTML = '';
            }, 4000);
        }
    }
}

export { MAILCHIMP_SERVICE }
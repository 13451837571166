import { UTILS } from './../utils/utils';

const SENDEMAIL_SERVICE = {
    async sendEmail(form) {
        emailjs.init({
            publicKey: process.env.EJS_API_KEY,
        });
        emailjs.sendForm(process.env.EJS_SERVICE_ID, process.env.EJS_TEMPLATE_ID, '#contact-form')
            .then((response) => {
                form.reset();
                form.querySelector('button[type="submit"] svg').classList.add('hidden');
                UTILS.toast('#82D955', 'Your message has been sent sucessfully!');
                console.log('SUCCESS!', response.status, response.text);
            },
            (error) => {
                form.querySelector('button[type="submit"] svg').classList.add('hidden');
                UTILS.toast('#FF6570', `${error}`);
                console.log('FAILED...', error);
            });
    }
}

export { SENDEMAIL_SERVICE }
/* Import CSS Plugins and main SCSS */
import 'animate.css/animate.min.css';
import 'aos/dist/aos.css';
import 'simplycountdown.js/css/simplyCountdown.theme.default.css';
import 'vanilla-cookieconsent/dist/cookieconsent.css'

/* Import Js Plugins (ES6 Module) and custom JS */
import AOS from 'aos';
import Rellax from 'rellax';
import ActiveMenuLink from 'active-menu-link';
import {  Modal } from 'flowbite'
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

import '../plugins/simplyCountdown.min';
import '../plugins/cookieconsent-init';

/* Global variables */
let lastScroll = 0;
let delta = 5;
let headerHeight = document.querySelector('header').offsetHeight;

const UTILS = {
    init() {
        AOS.init();
        new Rellax('.rellax');
        this.activeMenuLink();
        this.topNavigationMenu();
        this.countDown();
        this.pieChart();
        this.copyPrintYear();
    },
    activeMenuLink() {
        let options = {
            //showHash: false,
        };
        new ActiveMenuLink('.pt__top-nav', options);
    },
    topNavigationMenu() {
        let toggleMenuButton = document.querySelector('#toggle-menu-button');
        let menu = document.querySelector('#toggle-menu');
        let openMenuButton = document.querySelector('#open-menu-button');
        let closeMenuButton = document.querySelector('#close-menu-button');
        let itemMenu = document.querySelectorAll('#toggle-menu a');

        toggleMenuButton.addEventListener('click', (e) => {
            menu.classList.toggle('hidden');
            openMenuButton.classList.toggle('hidden');
            closeMenuButton.classList.toggle('hidden');
        });
        itemMenu.forEach((el, index) => {
            el.addEventListener('click', (e) => {
                e.preventDefault()
                toggleMenuButton.click();
            });
        });
    },
    headerAnimate() {
        let scrollTop = document.documentElement.scrollTop
        if (Math.abs(lastScroll - scrollTop) <= delta)
            return;
        if (scrollTop > lastScroll && scrollTop > headerHeight) {
            // scroll down
            document.querySelector('header').classList.remove('down');
            document.querySelector('header').classList.add('up');
        } else {
            // scroll up
            if (scrollTop + window.innerHeight < document.documentElement.scrollHeight) {
                document.querySelector('header').classList.remove('up');
                document.querySelector('header').classList.add('down');
            }
            if (scrollTop <= 50) {
                document.querySelector('header').classList.remove('down');
            }
        }
        lastScroll = scrollTop;
    },
    pieChart() {
        am5.ready(() => {
            const rootChart = am5.Root.new('chart-token');
            rootChart.setThemes([
                am5themes_Animated.new(rootChart)
            ]);
            rootChart._logo.dispose();

            let chart = rootChart.container.children.push(am5percent.PieChart.new(rootChart, {
                radius: am5.percent(70),
                innerRadius: am5.percent(70),
                layout: rootChart.horizontalLayout,
            }));

            let series = chart.series.push(am5percent.PieSeries.new(rootChart, {
                name: 'Token supply',
                categoryField: 'section',
                valueField: 'supply',
                legendValueText: '{value}%',
            }));
            series.get('colors').set('colors', [
                am5.color('#293856'),
                am5.color('#3A4863'),
                am5.color('#4D5971'),
                am5.color('#606B80'),
                am5.color('#737C8E'),
                am5.color('#999FAB'),
                am5.color('#ABB0B9')
            ]);

            series.data.setAll([{
                section: 'Team',
                supply: 22
            }, {
                section: 'Strategic Network',
                supply: 9
            }, {
                section: 'Public Sale',
                supply: 8
            }, {
                section: 'Private Sale',
                supply: 10
            }, {
                section: 'Advisory',
                supply: 3
            }, {
                section: 'Founds and treasury',
                supply: 18
            }, {
                section: 'Ecosystem',
                supply: 30
            }]);

            series.labels.template.set('visible', true);
            series.ticks.template.set('visible', true);
            series.labels.template.setAll({
                text: '{value}%',
                fontSize: 14
            });
            series.slices.template.set('strokeOpacity', 0);

            let legend = chart.children.push(am5.Legend.new(rootChart, {
                centerY: am5.percent(50),
                y: am5.percent(50),
                clickTarget: 'none',
                layout: rootChart.verticalLayout
            }));
            legend.valueLabels.template.setAll({
                textAlign: 'left',
                fontSize: 14
            })
            legend.labels.template.setAll({
                maxWidth: 200,
                width: 200,
                paddingTop: 5,
                paddingBottom: 5,
                horizontalGap: 0,
                fontSize: 14,
                oversizedBehavior: 'wrap',
            });
            legend.data.setAll(series.dataItems);

            if (window.innerWidth > 768) {
                legend.set('visible', true);
            } else {
                legend.set('visible', false);
            }
            window.addEventListener('resize', () => {
                if (window.innerWidth > 768) {
                    legend.set('visible', true);
                } else {
                    legend.set('visible', false);
                }
            });
            series.appear(1000, 100);
        });
    },
    countDown() {
        const options = {
            year: 2024,
            month: 12,
            day: 31,
            hours: 23,
            minutes: 59,
            seconds: 0,
            words: {
                days: { singular: 'day', plural: 'days' },
                hours: { singular: 'hour', plural: 'hours' },
                minutes: { singular: 'minute', plural: 'minutes' },
                seconds: { singular: 'second', plural: 'seconds' }
            },
            plural: true,
            inline: false,
            inlineClass: 'simply-countdown-inline',
            // in case of inline set to false
            enableUtc: false,
            onEnd: function () {
                // callback
            },
            refresh: 1000, //default refresh every 1s
            sectionClass: 'simply-section',
            amountClass: 'simply-amount',
            wordClass: 'simply-word',
            zeroPad: false,
            countUp: false, // enable count up if set to true
        }
        simplyCountdown('#countdown', options);
    },
    copyPrintYear() {
        const year = new Date().getFullYear();
        document.querySelector('footer .year').innerText = year;
    },
    toast(statusBg, message) {
        let wrapToast = document.body.innerHTML += `
            <div class="wrap-toast w-full flex justify-center">
                <div class="text-center text-white fixed bottom-[90px] px-4 py-3 rounded-lg z-50 animate__animated animate__fadeInUp" style="background-color: ${statusBg}">
                    ${message}
                </div>
            </div>
            
        `;
        setTimeout(() => {
            document.querySelector('.wrap-toast').remove();
        }, 3000);
    }
}

export { UTILS }
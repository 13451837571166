import Pristine from 'pristinejs/dist/pristine';
import { serializeFormData } from '../helpers/helpers';
import { MAILCHIMP_SERVICE } from '../services/mailchimpService';
import { SENDEMAIL_SERVICE } from '../services/sendEmailService';

const HANDLES = {
    init() {
        this.mailchimpLaunchAlertSubscribeForm();
        this.mailchimpSubscribeForm();
        this.contactForm();
    },
    mailchimpLaunchAlertSubscribeForm() {
        const forms = document.querySelectorAll('.mailchimp-launch-alert-subscribe-form');
        forms.forEach((form) => {
            const pristine = new Pristine(form);
            form.addEventListener('submit', (e) => {
                e.preventDefault();
                let valid = pristine.validate();
                console.log(valid)
                if (valid) {
                    form.querySelector('button').classList.add('is-loading');
                    let serializedForm = serializeFormData(form);
                    return MAILCHIMP_SERVICE.setListMemberOnlyEmail(serializedForm, form);
                }
            });
        })

    },
    mailchimpSubscribeForm() {
        const form = document.getElementById('mailchimp-subscribe-form');
        const pristine = new Pristine(form);
        form.addEventListener('submit', (e) => {
            e.preventDefault();
            let valid = pristine.validate();
            if (valid) {
                document.querySelector('#mailchimp-subscribe-form button svg').classList.remove('hidden');
                let serializedForm = serializeFormData(form);
                return MAILCHIMP_SERVICE.setListMember(serializedForm);
            }
        });
    },
    contactForm() {
        const form = document.getElementById('contact-form');
        const pristine = new Pristine(form);
        form.addEventListener('submit', (e) => {
            e.preventDefault();
            let valid = pristine.validate();
            if (valid) {
                // add spinner
                e.currentTarget.querySelector('button[type="submit"] svg').classList.remove('hidden');
                return SENDEMAIL_SERVICE.sendEmail(form);
            }
        });
    }
}

export { HANDLES }